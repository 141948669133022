<p-table [value]="tableData">
    <ng-template pTemplate="header">
        <tr>
            <th scope="col" class="text-center border-bottom" colspan="8"><b>Variance Analysis -- Annual Summary </b></th>
        </tr>
        <tr>
            <th scope="col">in '000s unless otherwise noted</th>
            <th class="text-right" *ngFor="let year of cols" scope="col">{{ year }}</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-i="rowIndex">
      <tr [style.background]="rowData?.displayName.includes('Variance') ? '#e6f4ff' : rowData?.displayName.includes('Current') ? ' #f2e6ff' : ''">
        <td [ngClass]="rowData?.displayNameStyles">{{ rowData.displayName.includes('Variance') ? 'Variance' : rowData.displayName }}</td>
        <td class="text-right" *ngFor="let year of cols" [style.color]="rowData.displayName !== 'Variance' ? 'blue' : '' "  [ngClass]="rowData?.displayNameStyles">{{ rowData[year] || 'N/A' }}</td>
      </tr>
      <tr *ngIf="isEndOfGroup(i, tableData)">
        <td colspan="100%" class="group-spacing"></td>
      </tr>
    </ng-template>
</p-table>
  